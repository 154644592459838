import React from 'react'
import {
  Footer, useTranslation, useLocalizedUrl
} from '@sailogy/shipwright'
import { K } from './i18n'


const WebsiteFooter: React.FC = () => {
  const t = useTranslation<K>()
  const localizedUrl = useLocalizedUrl()
  const menu = [
    { href: localizedUrl('/help_about'), label: t('About us') },
    { href: localizedUrl('/help_faq'), label: t('FAQ') },
    { href: localizedUrl('/help_careers'), label: t('Work with us') },
    { href: localizedUrl('/help_tec'), label: t('Terms & Conditions') },
    { href: localizedUrl('/help_privacy'), label: t('Privacy policy') },
    { href: localizedUrl('/help_cookies'), label: t('Cookie policy') },
    { href: localizedUrl('/help_imprint'), label: t('Imprint') },
    { href: 'https://fleet.sailogy-group.com', label: t('List your fleet') }
  ]

  return (
    <Footer
      templateId={process.env.TRUSTPILOT_MICROBUTTON_ID!}
      businessunitId={process.env.TRUSTPILOT_BUSINESSUNIT!}
      userId={process.env.TRUSTPILOT_USER_ID!}
      menu={menu}
      copy={t('Part of Sailogy Group')}
    />
  )
}

export default React.memo(WebsiteFooter)
