type Props = React.SVGProps<SVGSVGElement>

const MasterYachtingLogo = (props: Props) => (
  <svg version="1.1" viewBox="0 0 311 101" width={105} height={38} {...props}>
    <clipPath id="clip1">
      <path d="M 286 67 L 310.628906 67 L 310.628906 100.699219 L 286 100.699219 Z M 286 67" />
    </clipPath>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Logo" fill="currentColor">
        <path d="M 34.222656 26.203125 L 0 91.222656 L 6.96875 91.222656 L 34.222656 39.4375 Z M 34.222656 26.203125 " />
        <path d="M 50.925781 13.828125 L 10.1875 91.222656 L 18.480469 91.222656 L 50.925781 29.582031 Z M 50.925781 13.828125 " />
        <path d="M 22.003906 91.222656 L 70.019531 0 L 70.019531 91.222656 " />
        <path d="M 97.527344 17.765625 L 106.171875 17.765625 L 110.902344 31.503906 C 111.628906 33.59375 112.539063 36.871094 112.539063 36.871094 L 112.628906 36.871094 C 112.628906 36.871094 113.539063 33.59375 114.265625 31.503906 L 118.996094 17.765625 L 127.640625 17.765625 L 130.277344 50.285156 L 122.316406 50.285156 L 121.316406 35.640625 C 121.136719 33.183594 121.316406 30.183594 121.316406 30.183594 L 121.226563 30.183594 C 121.226563 30.183594 120.132813 33.59375 119.40625 35.640625 L 115.996094 45.058594 L 109.171875 45.058594 L 105.761719 35.640625 C 105.03125 33.59375 103.941406 30.183594 103.941406 30.183594 L 103.851563 30.183594 C 103.851563 30.183594 104.03125 33.183594 103.851563 35.640625 L 102.851563 50.285156 L 94.84375 50.285156 " />
        <path d="M 143.507813 44.828125 C 145.554688 44.828125 147.375 42.601563 147.375 40.691406 L 147.375 40.191406 L 146.644531 40.191406 C 144.144531 40.191406 141.28125 40.917969 141.28125 42.875 C 141.28125 43.964844 141.960938 44.828125 143.507813 44.828125 M 146.964844 35.6875 L 147.285156 35.6875 L 147.285156 35.277344 C 147.285156 33.320313 145.78125 32.683594 143.828125 32.683594 C 140.871094 32.683594 137.503906 34.914063 137.503906 34.914063 L 134.777344 29.363281 C 134.777344 29.363281 138.824219 26.316406 144.601563 26.316406 C 151.195313 26.316406 155.152344 29.910156 155.152344 35.867188 L 155.152344 50.289063 L 147.964844 50.289063 L 147.964844 48.878906 C 147.964844 48.105469 148.058594 47.421875 148.058594 47.421875 L 147.964844 47.421875 C 147.964844 47.421875 146.238281 50.832031 141.324219 50.832031 C 136.960938 50.832031 133.363281 47.921875 133.363281 43.28125 C 133.363281 36.460938 143.328125 35.6875 146.964844 35.6875 " />
        <path d="M 161.941406 42.101563 C 161.941406 42.101563 164.53125 44.464844 167.898438 44.464844 C 169.082031 44.464844 170.21875 44.148438 170.21875 43.375 C 170.21875 41.328125 159.257813 41.101563 159.257813 33.457031 C 159.257813 29.046875 163.488281 26.316406 168.898438 26.316406 C 174.359375 26.316406 177.132813 29 177.132813 29 L 174.449219 34.59375 C 174.449219 34.59375 172.175781 32.683594 168.898438 32.683594 C 167.71875 32.683594 166.578125 33.003906 166.578125 33.824219 C 166.578125 35.597656 177.542969 35.867188 177.542969 43.417969 C 177.542969 47.421875 173.992188 50.832031 167.945313 50.832031 C 162.078125 50.832031 158.574219 47.378906 158.574219 47.378906 " />
        <path d="M 183.21875 33.414063 L 180.214844 33.414063 L 180.214844 27.273438 L 183.445313 27.273438 L 183.445313 20.539063 L 191.085938 20.539063 L 191.085938 27.273438 L 196 27.273438 L 196 33.414063 L 191.085938 33.414063 L 191.085938 40.597656 C 191.085938 43.238281 193.769531 43.601563 195.273438 43.601563 C 195.90625 43.601563 196.316406 43.554688 196.316406 43.554688 L 196.316406 50.378906 C 196.316406 50.378906 195.453125 50.515625 194.179688 50.515625 C 190.449219 50.515625 183.21875 49.515625 183.21875 41.644531 " />
        <path d="M 214.328125 35.597656 C 214.328125 33.824219 213.101563 32.230469 211.238281 32.230469 C 208.964844 32.230469 207.871094 33.867188 207.464844 35.597656 Z M 211.421875 26.316406 C 218.332031 26.316406 222.246094 31.136719 222.246094 37.644531 C 222.246094 38.414063 222.0625 40.097656 222.0625 40.097656 L 207.28125 40.097656 C 207.871094 42.964844 210.234375 44.285156 212.738281 44.285156 C 216.105469 44.285156 219.195313 41.917969 219.195313 41.917969 L 222.109375 47.421875 C 222.109375 47.421875 218.332031 50.832031 212.101563 50.832031 C 203.730469 50.832031 199.230469 44.785156 199.230469 38.554688 C 199.230469 31.683594 203.871094 26.316406 211.421875 26.316406 " />
        <path d="M 226.085938 26.863281 L 233.636719 26.863281 L 233.636719 30.820313 C 233.636719 31.59375 233.546875 32.277344 233.546875 32.277344 L 233.636719 32.277344 C 234.636719 29.09375 237.59375 26.453125 241.097656 26.453125 C 241.550781 26.453125 242.003906 26.542969 242.003906 26.542969 L 242.003906 34.277344 C 242.003906 34.277344 241.367188 34.140625 240.414063 34.140625 C 238.546875 34.140625 235.5 34.730469 234.363281 38.234375 C 234.089844 39.097656 233.953125 40.144531 233.953125 41.417969 L 233.953125 50.289063 L 226.085938 50.289063 " />
        <path d="M 133.914063 77.820313 L 123.179688 58.625 L 132.230469 58.625 L 135.914063 66.449219 C 136.964844 68.675781 137.871094 71.132813 137.871094 71.132813 L 137.964844 71.132813 C 137.964844 71.132813 138.871094 68.675781 139.917969 66.449219 L 143.558594 58.625 L 152.609375 58.625 L 141.875 77.820313 L 141.875 91.148438 L 133.914063 91.148438 " />
        <path d="M 159.773438 85.691406 C 161.820313 85.691406 163.640625 83.460938 163.640625 81.550781 L 163.640625 81.050781 L 162.910156 81.050781 C 160.410156 81.050781 157.542969 81.777344 157.542969 83.734375 C 157.542969 84.824219 158.226563 85.691406 159.773438 85.691406 M 163.230469 76.546875 L 163.546875 76.546875 L 163.546875 76.136719 C 163.546875 74.179688 162.046875 73.542969 160.089844 73.542969 C 157.136719 73.542969 153.769531 75.773438 153.769531 75.773438 L 151.039063 70.222656 C 151.039063 70.222656 155.089844 67.175781 160.863281 67.175781 C 167.460938 67.175781 171.417969 70.769531 171.417969 76.730469 L 171.417969 91.148438 L 164.230469 91.148438 L 164.230469 89.738281 C 164.230469 88.964844 164.320313 88.28125 164.320313 88.28125 L 164.230469 88.28125 C 164.230469 88.28125 162.503906 91.695313 157.589844 91.695313 C 153.222656 91.695313 149.628906 88.78125 149.628906 84.144531 C 149.628906 77.320313 159.589844 76.546875 163.230469 76.546875 " />
        <path d="M 187.367188 67.175781 C 193.734375 67.175781 196.691406 70.726563 196.691406 70.726563 L 193.371094 76 C 193.371094 76 191.097656 73.726563 187.871094 73.726563 C 184.140625 73.726563 182.273438 76.546875 182.273438 79.324219 C 182.273438 82.097656 184.183594 85.144531 188.140625 85.144531 C 191.414063 85.144531 194.554688 82.324219 194.554688 82.324219 L 197.421875 87.828125 C 197.421875 87.828125 193.917969 91.695313 187.367188 91.695313 C 179 91.695313 174.3125 85.871094 174.3125 79.410156 C 174.3125 73.136719 178.953125 67.175781 187.367188 67.175781 " />
        <path d="M 200.527344 58.625 L 208.394531 58.625 L 208.394531 68.90625 C 208.394531 70.089844 208.304688 70.90625 208.304688 70.90625 L 208.394531 70.90625 C 209.71875 68.585938 212.445313 67.175781 215.539063 67.175781 C 220.269531 67.175781 223.953125 69.359375 223.953125 75.957031 L 223.953125 91.148438 L 216.085938 91.148438 L 216.085938 77.410156 C 216.085938 75.226563 215.21875 74.320313 213.492188 74.320313 C 211.082031 74.320313 209.488281 75.730469 208.808594 77.777344 C 208.488281 78.683594 208.394531 79.6875 208.394531 80.777344 L 208.394531 91.148438 L 200.527344 91.148438 " />
        <path d="M 229.71875 74.273438 L 226.714844 74.273438 L 226.714844 68.132813 L 229.945313 68.132813 L 229.945313 61.402344 L 237.585938 61.402344 L 237.585938 68.132813 L 242.496094 68.132813 L 242.496094 74.273438 L 237.585938 74.273438 L 237.585938 81.460938 C 237.585938 84.097656 240.269531 84.460938 241.769531 84.460938 C 242.40625 84.460938 242.816406 84.417969 242.816406 84.417969 L 242.816406 91.238281 C 242.816406 91.238281 241.953125 91.375 240.679688 91.375 C 236.949219 91.375 229.71875 90.375 229.71875 82.503906 " />
        <path d="M 246.886719 58.625 L 254.574219 58.625 L 254.574219 64.765625 L 246.886719 64.765625 Z M 246.796875 67.722656 L 254.664063 67.722656 L 254.664063 91.148438 L 246.796875 91.148438 Z M 246.796875 67.722656 " />
        <path d="M 259.5625 67.722656 L 267.113281 67.722656 L 267.113281 69.769531 C 267.113281 70.542969 267.023438 71.226563 267.023438 71.226563 L 267.113281 71.226563 C 268.207031 69.40625 270.433594 67.175781 274.574219 67.175781 C 279.304688 67.175781 282.988281 69.359375 282.988281 75.957031 L 282.988281 91.148438 L 275.121094 91.148438 L 275.121094 77.410156 C 275.121094 75.226563 274.253906 74.320313 272.527344 74.320313 C 270.070313 74.320313 268.570313 75.683594 267.886719 77.683594 C 267.570313 78.59375 267.433594 79.640625 267.433594 80.777344 L 267.433594 91.148438 L 259.5625 91.148438 " />
        <g clipPath="url(#clip1)" clipRule="nonzero" />
        <path d="M 302.941406 78.777344 C 302.941406 74.636719 300.757813 73.726563 298.390625 73.726563 C 295.617188 73.726563 294.207031 75.730469 294.207031 78.410156 C 294.207031 81.324219 295.570313 83.914063 298.710938 83.914063 C 300.757813 83.914063 302.941406 82.871094 302.941406 78.777344 M 297.347656 94.148438 C 300.164063 94.148438 302.757813 93.105469 302.757813 89.648438 L 302.757813 88.875 C 302.757813 88.601563 302.804688 88.007813 302.804688 88.007813 L 302.714844 88.007813 C 301.347656 89.648438 299.574219 90.464844 296.84375 90.464844 C 289.796875 90.464844 286.292969 84.6875 286.292969 78.683594 C 286.292969 72.726563 289.703125 67.175781 296.800781 67.175781 C 301.074219 67.175781 303.167969 69.453125 303.167969 69.453125 L 303.304688 69.453125 C 303.304688 69.453125 303.257813 69.175781 303.257813 68.90625 L 303.257813 67.722656 L 310.628906 67.722656 L 310.628906 89.011719 C 310.628906 97.5625 303.804688 100.699219 297.390625 100.699219 C 294.253906 100.699219 290.796875 99.789063 288.523438 98.609375 L 290.75 92.648438 C 290.75 92.648438 293.660156 94.148438 297.347656 94.148438 " />
      </g>
    </g>
  </svg>
)

export default MasterYachtingLogo
